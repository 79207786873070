/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { computeTimeFrame } from 'app/utils/dates';
import { FieldDisplayer } from 'app/components/common';
import { getQueryParams } from 'app/utils/queryParams';
import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import {
  MonthlyContributionVariantResponse,
  MonthlyContributionVariantSimulation,
  MutualFundsSimulationVariantsResponse,
  SimulateVariants,
  TimeFrameVariantResponse,
  TimeFrameVariantSimulation,
} from './mutualFundsService';

interface TimeFrameCounterProps {
  simulate: SimulateVariants
  time_frame: number
}

export function TimeFrameDisplayer({ time_frame, simulate }: TimeFrameCounterProps) {
  const timeFrameForVariant = simulate === 'monthly_contribution'
    // If time frame is given by the user as input (in monthly contribution variant)
    // It is given as years so we need to give months amount for those years
    ? Math.floor(time_frame * 12)

    // If time frame is taken from backend response (in time frame variant)
    // It is given as months so we keep its value without any transform
    : time_frame;

  const {
    hasMonths, hasYears, months, years,
  } = computeTimeFrame(timeFrameForVariant);

  return (
    <>
      {hasYears
        && (
          <>
            <span className="me-2 text-dark">{years}</span>
            <span className="me-2 text-secondary">
              {years === 1 ? 'año' : 'años'}
              {hasMonths && ','}
            </span>
          </>
        )}

      {
        hasMonths
        && (
          <>
            <span className="me-2 text-dark">{months}</span>
            <span className="text-secondary">{months === 1 ? 'mes' : 'meses'}</span>
          </>
        )
      }
    </>
  );
}

export function TimeFrameCounter({ time_frame }: TimeFrameCounterProps) {
  const { monthly_contribution, simulate } = getQueryParams<TimeFrameVariantSimulation>();

  return (
    <div className="d-flex flex-column flex-md-row gap-3 gap-md-2">
      <FieldDisplayer
        overwrittenClassName="fw-bold px-2_5 py-2 d-inline-block rounded-4 bg-light-secondary"
        renderContent={<TimeFrameDisplayer simulate={simulate} time_frame={time_frame} />}
      />
      <FieldDisplayer
        overwrittenClassName="fw-bold px-2_5 py-2 d-inline-block rounded-4 bg-light-secondary"
        renderContent={(
          <>
            <span className="me-2 text-dark">{r(monthly_contribution, withPrefix)}</span>
            <span className="text-secondary">mensuales</span>
          </>
        )}
      />
    </div>
  );
}

interface MonthlyContributionCounterProps {
  monthly_contribution: number
}

export function MonthlyContributionCounter(
  { monthly_contribution }: MonthlyContributionCounterProps
) {
  const { time_frame, simulate } = getQueryParams<MonthlyContributionVariantSimulation>();

  return (
    <div className="d-flex flex-column flex-md-row gap-3 gap-md-2">
      <FieldDisplayer
        overwrittenClassName="fw-bold px-2_5 py-2 d-inline-block rounded-4 bg-light-secondary"
        renderContent={(
          <>
            <span className="me-2 text-dark">{r(monthly_contribution, withPrefix)}</span>
            <span className="text-secondary">mensuales</span>
          </>
        )}
      />
      <FieldDisplayer
        overwrittenClassName="fw-bold px-2_5 py-2 d-inline-block rounded-4 bg-light-secondary"
        renderContent={<TimeFrameDisplayer simulate={simulate} time_frame={time_frame} />}
      />
    </div>
  );
}

interface VariantResponseCounterProps {
  data: MutualFundsSimulationVariantsResponse,
  simulate: SimulateVariants
}

export function VariantResponseCounter({ data, simulate }: VariantResponseCounterProps) {
  if (simulate === 'monthly_contribution') {
    return (
      <MonthlyContributionCounter
        monthly_contribution={(
          data as MonthlyContributionVariantResponse).monthly_contribution}
      />
    );
  }

  if (simulate === 'time_frame') {
    return (
      <TimeFrameCounter
        simulate={simulate}
        time_frame={(data as TimeFrameVariantResponse).time_frame}
      />
    );
  }

  // Should never render this case!
  return null;
}
