import { getIcon } from 'app/utils/staticStorage';
import React from 'react';
import { BaseAlert } from './BaseAlert';

export function RealRentabilityDisclaimer() {
  return (
    <BaseAlert
      alertClassName="text-body small border-1 border-info"
      icon={{
        src: getIcon('info.svg'),
        alt: 'info icon',
      }}
      type="alert-info"
      renderContent={() => (
        <p className="m-0">
          Estos cálculos se realizan utilizando rentabilidades reales.
        </p>
      )}
    />

  );
}
