import {
  CompensationPlan, Genders, WithCurrencyInfo, WithEmptyOption,
} from 'app/utils/types';
import { api } from 'app/utils/api';
import { SIMULATION_ENDPOINTS } from 'app/utils/endpoints';
import { WithPolicyByYearData } from 'app/utils/hooks/useBuildTable';

export type FullFlexibleSavingOption = 'savings_capacity' | 'goal_value';

export interface FullFlexiblePayload extends WithEmptyOption {
  date_of_birth: string
  gender: Genders
  goal: number
  timeframe: number
  rentability: number
  compensation_plan: CompensationPlan
  death_insured_capital: number
  itp_insured_capital: number
  serious_illness_insured_capital: number
  savings_option: FullFlexibleSavingOption
  agreed_premium: number
}

export interface FullFlexibleResponse extends WithCurrencyInfo, WithPolicyByYearData {
  agreed_premium: number
  average_monthly_cost: number
  goal: number
  monthly_insurance_cost: number
  monthly_savings: number
  timeframe: number
  total_base_premium: number
  total_insurance_cost: number
  total_personal_savings_without_interest: number
  total_savings_interest: number
  total_savings: number
}

export const fullFlexibleService = {
  simulate: async (payload: FullFlexiblePayload) => api
    .post<FullFlexibleResponse>(SIMULATION_ENDPOINTS.FULL_FLEXIBLE, payload),
};
